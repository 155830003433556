<template>
<div class="row">
<div class="col-md-12">

      <div class="d-flex position-relative">
          <img :src="imglocal+'/imagenes/ico/encuestas.svg'" class="me-3"  style="width: 40px; height: 40px;" alt="">
          <div class="media-body">
            <span class="h5 titulosg ">Encuestas</span>
          </div>
        </div>
        <div class="card card-body shadow-sm mt-3">
            <table class="table table-hover" >
                <thead>
                    <tr>
                        <td></td>
                        <td>Nombre</td>
                        <td>Url</td>
                        <td>Estado</td>
                    </tr>                    
                </thead>
                <tbody>
                    <tr  v-for="(item, index) in encuestas" :key="item.idencuesta" @click="verencuesta(item.idencuesta)">
                        <td style="width: 15px;" class="text-center">{{index+1}}</td>
                        <td><strong>{{item.tituloencuesta}}</strong> <br>
                            <span class="text-black-50">{{item.descripcionencuesta}}</span>
                        </td>
                        <td>
                            /{{item.slug}}                                                       
                        </td>
                        <td>
                            <span class="badge bg-success" v-if="item.estado == 1">Activo</span>
                            <span class="badge bg-success" v-if="item.estado == 0">Cerrado</span>
                        </td>
                    </tr>
                </tbody>
            </table>
 
        </div>

</div>
</div>
</template>

<script>

import {ref, onMounted} from 'vue'
import axios from 'axios'
import router from '../../router'


export default {
    setup(props) {

        const encuestas = ref({})

        const listarEncuestas = () => {
            axios.get('/api/encuestas').then(response => { 
                encuestas.value = response.data
            })
        }

        const verencuesta = (id) => {
            router.push({  name: 'infoencuesta', params: { id: id } });
        }

        onMounted(() => {
            listarEncuestas()
        })

        return { encuestas, verencuesta }
    }  
}
</script>
